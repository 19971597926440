<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/projects"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.projectAppointments.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="onFilterModal">
            <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="appointment in appointments"
        :key="appointment.id"
        @click="onSelect(appointment)"
        button
      >
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
              <ion-thumbnail v-if="appointment.store.retailer.logo">
                <img :src="appointment.store.retailer.logo" />
              </ion-thumbnail>
              <ion-thumbnail v-else>
                <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
              </ion-thumbnail>
            </ion-col>
            <ion-col>
              <ion-card-subtitle>
                {{ appointment.store.retailer.name }} -
                {{ appointment.store.name }} -
                {{ appointment.store.storeType.name }}
              </ion-card-subtitle>
              <ion-card-title v-if="appointment.intervention">
                {{ formatDate(appointment.intervention) }}
              </ion-card-title>
              <ion-card-title v-else-if="appointment.startsAt">
                {{ formatDate(appointment.startsAt) }}
              </ion-card-title>
              <ion-card-title v-else>
                {{ $t('labels.none') }}
              </ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ODNBadge :color="appointment.appointmentType.color">
            {{ $t(`appointmentTypes.${appointment.appointmentType.name}`) }}
          </ODNBadge>
          <ODNBadge>
            {{
              $t(`appointmentStatuses.${appointment.appointmentStatus.name}`)
            }}
          </ODNBadge>
          <p v-if="appointment.title">
            <strong>{{ appointment.title }}</strong>
          </p>
          <p>
            {{ appointment.user.firstname }} {{ appointment.user.lastname }} ({{
              appointment.user.email
            }})
          </p>
          <div v-html="appointment.notes" class="odn-mat-16"></div>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="appointments.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  IonThumbnail,
  IonRow,
  IonCol,
  modalController,
  toastController,
} from '@ionic/vue';
import { filterOutline } from 'ionicons/icons';

import ODNModalProjectAppointmentsFilters from '@m/odn-modal-project-appointments-filters.vue';
import ODNNoContent from '@c/odn-no-content.vue';
import ODNBadge from '@c/odn-badge.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    IonThumbnail,
    IonRow,
    IonCol,
    ODNNoContent,
    ODNBadge,
  },
  computed: {
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  data() {
    return {
      appointments: [],
      filters: {
        stores: [],
        appointmentTypes: [],
        appointmentStatuses: [],
        sort: 'appointment.intervention',
        direction: 'desc',
      },
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      filterOutline,
      loading: true,
    };
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.appointments = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('appointments', ['selectAppointment']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get(
          `/projects/${this.$route.params.id}/appointments`,
          {
            page: this.page,
            limit: this.limit,
            search: this.searchText,
            sort: this.filters.sort,
            direction: this.filters.direction,
            stores: this.filters.stores.length
              ? this.filters.stores.map(r => r.id).join(',')
              : null,
            appointmentTypes: this.filters.appointmentTypes.length
              ? this.filters.appointmentTypes.join(',')
              : null,
            appointmentStatuses: this.filters.appointmentStatuses.length
              ? this.filters.appointmentStatuses.join(',')
              : null,
          },
        );

        if (clear) {
          this.appointments = [];
        }

        this.appointments = this.appointments.concat(response.data.items);

        if (this.appointments.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.appointments.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onSearch() {
      await this.fetchData(true);
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    async onFilterModal() {
      const modal = await modalController.create({
        component: ODNModalProjectAppointmentsFilters,
        componentProps: {
          filters: this.filters,
        },
      });
      await modal.present();

      const newFilters = (await modal.onDidDismiss()).data;

      if (newFilters) {
        this.filters = newFilters;
        this.fetchData(true);
      }
    },
    onSelect(appointment) {
      this.selectAppointment(appointment);
      this.$router.push(
        `/tabs/projects/${this.$route.params.id}/appointments/${appointment.id}`,
      );
    },
  },
};
</script>
